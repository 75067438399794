<template>
  <hr />
  <div v-for="art in artwork" :key="art.id" class="row">
    <div class="col-sm-6 col-xs-12 mb-5">
      <img class="img-fluid" :src="`./assets/artwork/${art.imgName}`" />
    </div>
    <div class="col-sm-6 col-xs-12">
      <div class="card-body">
        <h5 class="card-title">{{ art.name }}</h5>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <strong>available NFT:</strong> {{ art.nft }}
          </li>
          <li v-snip="{ lines: 3 }" class="list-group-item mb-2">
            <strong>Description:</strong> {{ art.description }}
          </li>
        </ul>

        <a href="#" class="btn btn-danger mt-3">More INFO</a>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
// import { onMounted, onUnmounted, onUpdated } from "vue";
// import nftDetail from "./nftDetail.vue";

export default {
  props: ["artwork"],
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm;
    },
  },
};
</script>

<style></style>
