<template>
  <header class="row">
    <div class="branding col-12 col-md-6 text-center">
      <img
        src="@/assets/img/JayMadeLogo.png"
        alt="JayMade: {{ tagline }}"
        class="img-fluid"
      />
      <p class="tag text-center">{{ tagline }}</p>
    </div>
    <nav class="col-12 col-md-6 pt-md-5 mt-md-5 text-center">
      <router-link to="/">Artwork</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/contact">Contact</router-link>
    </nav>
  </header>
  <main class="container-fluid">
    <router-view />
  </main>
</template>
<script>

export default {
  data() {
    return {
      tagline: "Artwork by Jay Rich",
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
#app {
  font-family: "Works Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

nav a {
  font-weight: bold;
  color: $dkGray;
  padding: 0 5px;
  text-decoration: none;

  &.router-link-exact-active {
    color: $red;
    text-decoration: none;
  }
}
h1 {
  padding-top: 3rem;
}
</style>
