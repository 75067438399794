<template>
  <div class="artwork">
    <h1>Artwork</h1>
    <div v-if="artwork.length">
      <nftList :artwork="artwork" />
    </div>
    <div v-else>LOADING...</div>
  </div>
</template>

<script>
// import axios from "axios";
import nftList from "../components/nftList.vue";
// import { ref } from "vue";

export default {
  name: "Artwork",
  components: { nftList },
  data() {
    return {
      artwork: [
        {
          id: 100,
          name: "Jimi",
          type: "painting",
          aspect: "portrait",
          subject: "portrait",
          description:
            "Jimi. The man, the myth, the legend. He changed music and mindsets for generations. truly an inspiration and one of my first pieces of art.",
          imgName: "jimi.jpg",
          nft: "considering",
        },
        {
          id: 101,
          name: "Jamie",
          type: "painting",
          aspect: "portrait",
          subject: "portrait",
          description:
            "Jamie, a beauty from Carolina. Admired from a far, inspiring me to capture her glance with acrylics on canvase",
          imgName: "Jamie.jpg",
          nft: "considering",
        },
        {
          id: 102,
          name: "Johnny",
          type: "painting",
          aspect: "portrait",
          subject: "portrait",
          description:
            "The man in black. on eof my all time favorite muscicials. His music stire up the out law in all of us. ",
          imgName: "johnny.jpg",
          nft: "considering",
        },
        {
          id: 103,
          name: "Shotglass POV",
          type: "painting",
          aspect: "landscape",
          subject: "portrait",
          description:
            "A perspective from that little glass holding truth syrum, typically consumed in a syngle gulp",
          imgName: "shotGlassPOV.jpg",
          nft: "considering",
        },
        {
          id: 104,
          name: "Bob",
          type: "painting",
          aspect: "portrait",
          subject: "portrait",
          description:
            "My very first painting. I found Bob in mid 86 and have been listening to his words ever since.",
          imgName: "Bob.jpg",
          nft: "considering",
        },
        {
          id: 105,
          name: "Alais",
          type: "painting",
          aspect: "portrait",
          subject: "portrait",
          description: "A dear friend to DD",
          imgName: "Alias.jpg",
          nft: "unavailable",
        },
        {
          id: 105,
          name: "Close up",
          type: "painting",
          aspect: "portrait",
          subject: "abstract",
          description: "up close and personal",
          imgName: "closeUp.jpg",
          nft: "considering",
        },
        {
          id: 106,
          name: "Damn Spiders",
          type: "Illustration",
          aspect: "portrait",
          subject: "abstract",
          description: "They could be any where",
          imgName: "DamnSpiders.jpg",
          nft: "considering",
        },
        {
          id: 107,
          name: "Flamingos",
          type: "Digital Illustration",
          aspect: "portrait",
          subject: "abstract",
          description: "Pink, it my favoright color",
          imgName: "flamingo.jpg",
          nft: "considering",
        },
        {
          id: 108,
          name: "Hali",
          type: "Painting",
          aspect: "portrait",
          subject: "abstract",
          description: "Ms. Berry",
          imgName: "hali.jpg",
          nft: "considering",
        },
        {
          id: 109,
          name: "Jessica",
          type: "Painting",
          aspect: "portrait",
          subject: "abstract",
          description: "yea, her",
          imgName: "Jessica.jpg",
          nft: "considering",
        },
      ],
    };
  },
};
</script>
